import {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import InView from 'react-intersection-observer';

import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

import {EDetailButtonType, EPlaceCategoryType, TPoiItem} from 'types/App';
import {TApiStatus} from 'types/Api';
import {EPerformanceLogKey} from 'types/Log';
import {EListMode} from 'types/ListDrawer';

import useThrottle from 'hooks/useThrottle';
import useLogger from 'hooks/useLogger';
import {EListActionId} from 'constant/Log';
import {useOnce} from 'hooks/useOnce';
import {usePerformanceLog} from 'hooks/usePerformanceLog';
import {generateTagComponentList} from 'hooks/usePoiMainContentTag';

import {debounce} from 'utils/lodash';

import {PoiListItem} from 'components/PoiListItem';
import NoResult from 'components/NoResult';
import ErrorReload from 'components/ErrorReload';
import {BlossomBikeButton} from 'components/BlossomBikeButton';

import s from 'styles/components/place/PlaceList.module.scss';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {useSearchImageList} from 'hooks/useSearchImageList';
import useAddress from 'hooks/useAddress';
import {getAppActionButton} from 'utils/tmapUtils';

type TProps = {
  apiStatus: TApiStatus<{
    list: TPoiItem[];
    currentPage: number;
    pagingSize: number;
    totalCount: number;
  }>;
  categoryType?: string;
  onFetchMore?: VoidFunction;
  onError?: VoidFunction;
  onClickAutoSearch?: VoidFunction;
};

const DEBOUNCE_DELAY = 300;
const CATEGORY_LIST_ERROR_TOP = 60;
const FIRST_MARKER = 'first';

const PlaceList = memo(({apiStatus, categoryType, onError, onFetchMore}: TProps) => {
  const {selectAddress} = useAddress();
  const {moveToDetail, moveToSelectDestinationAction, reqMode, reqType, extra} = useMoveToTarget();
  const dispatch = useAppDispatch();
  const {
    data: {list, pagingSize, totalCount},
    error,
    loaded,
    loading,
  } = apiStatus;
  const visibleItemList = useRef<(boolean | string)[]>([]);
  const {sendClickLog, sendNearbyItemClickLog, sendClickLogWithMapView} = useLogger();
  const {activePoi, drawerMode, isLandscape, euk} = useAppSelector((state) => ({
    activePoi: state.userInteraction.activePoi,
    drawerMode: state.userInteraction.drawerMode,
    isLandscape: state.layout.appSize.isLandscape,
    euk: state.userInfo.euk,
  }));
  const isFixFirstItem = useMemo(
    () => !isLandscape && drawerMode === EListMode.BOTTOM,
    [isLandscape, drawerMode]
  );
  const ableToRun = useThrottle();
  const {endLog} = usePerformanceLog();
  const imageCounter = useSearchImageList();
  const actionButtonType = getAppActionButton({reqMode, reqType, extra});

  useOnce(loaded, () => {
    endLog(EPerformanceLogKey.INIT_API_RENDER);
  });

  const updateFocusItem = useCallback(
    debounce(() => {
      const checkValue = !isFixFirstItem && drawerMode === EListMode.BOTTOM ? FIRST_MARKER : true;
      const idx = visibleItemList.current.findIndex((visible) => visible === checkValue);

      const item = list[idx];

      if (item) {
        dispatch(
          actions.userInteraction.setInteraction({
            activePoi: item.listId,
            trigger: 'scroll',
          })
        );
      }
    }, DEBOUNCE_DELAY),
    [list, drawerMode, isFixFirstItem]
  );

  const changeViewingItems = useCallback(
    (i, visible) => {
      if (
        !isFixFirstItem &&
        drawerMode === EListMode.BOTTOM &&
        visibleItemList.current.length === 0
      ) {
        visibleItemList.current[0] = FIRST_MARKER;
      } else {
        visibleItemList.current[i] = visible;
      }

      updateFocusItem();
    },
    [updateFocusItem, isFixFirstItem, drawerMode]
  );

  useEffect(() => {
    if (loading && !loaded && list.length < 1) {
      visibleItemList.current = [];
    }
  }, [loading, loaded, list]);

  useEffect(() => {
    if (loaded) {
      dispatch(actions.userInteraction.setRefreshStart(false));
    }
  }, [dispatch, loaded]);

  const handleClickReload = useCallback(() => {
    if (ableToRun()) {
      onError?.();
      sendClickLogWithMapView(EListActionId.FAIL_REFRESH, {}, {includeTicketId: true});
    }
  }, [ableToRun, onError, sendClickLogWithMapView]);

  const getItemLog = useCallback(
    (v: TPoiItem, i) => {
      const {catchTableWaitingResponse} = v?.special || {};
      const {
        isAvailableReservation,
        isAvailableWaiting,
        isAvailableOnlineWaiting,
        onlineWaitingDisableReason,
        unit,
      } = catchTableWaitingResponse || {};

      return {
        list_num: totalCount,
        list_seq: i + 1,
        pkey: v.pkey,
        page: Math.floor(i / pagingSize) + 1,
        is_catch: !!catchTableWaitingResponse,
        is_reserve: !!isAvailableReservation,
        is_waiting: !!isAvailableWaiting,
        ...(!!isAvailableWaiting && {
          waiting_available: isAvailableOnlineWaiting,
          waiting_count: unit?.count,
          waiting_not_available_reason: onlineWaitingDisableReason,
        }),
      };
    },
    [totalCount, pagingSize]
  );

  const handleClickItem = useCallback(
    (v, i, custom?: {tags}) => {
      const param = getItemLog(v, i);

      sendNearbyItemClickLog(EListActionId.POI_DETAIL, {
        ...param,
        tag: (custom?.tags || []).map((t) => t.type).join(', '),
        euk,
      });

      moveToDetail(
        parsePoiInfoToNavInfo({
          pkey: v.pkey || '',
          poiId: v.poiId || '',
          navSeq: `${v.navSeq || ''}`,
          navX: `${v?.navX || ''}`,
          navY: `${v?.navY || ''}`,
          centerX: `${v?.centerX || ''}`,
          centerY: `${v?.centerY || ''}`,
          rpFlag: v.rpFlag,
          poiName: v.listName || v.poiName,
          address: selectAddress({jibun: v.fullJibunAddr, road: v.fullRoadAddr}),
          tel: v.displayPhoneNumber,
        })
      );
    },
    [getItemLog, moveToDetail, selectAddress, sendNearbyItemClickLog, euk]
  );

  const handleClickRoute = useCallback(
    (v, i, custom?: {tags}) => {
      const param = getItemLog(v, i);
      const actionId =
        actionButtonType === EDetailButtonType.CONFIRM
          ? 'list_tap.select_poi'
          : 'list_tap.poi_direction';

      sendNearbyItemClickLog(actionId, {
        ...param,
        tag: (custom?.tags || []).map((t) => t.type).join(', '),
        euk,
      });
      moveToSelectDestinationAction(
        parsePoiInfoToNavInfo({
          ...v,
          address: selectAddress({jibun: v.fullJibunAddr, road: v.fullRoadAddr}),
          poiName: v.listName || v.poiName,
          tel: v.displayPhoneNumber,
          ...(v.stationInternalId && {
            stationId: v.stationInternalId,
            publicTransportType: v.stationType,
          }),
        })
      );
    },
    [
      getItemLog,
      sendNearbyItemClickLog,
      moveToSelectDestinationAction,
      selectAddress,
      actionButtonType,
      euk,
    ]
  );

  const errorMessages = useMemo(() => {
    if (!loaded || list.length > 0) {
      return null;
    }

    if (error) {
      return {
        reload: true,
      };
    }

    return null;
  }, [loaded, error, list.length]);

  if (errorMessages) {
    return (
      <ErrorReload
        onReload={errorMessages.reload ? handleClickReload : undefined}
        top={CATEGORY_LIST_ERROR_TOP}
      />
    );
  }

  if (loaded && list.length === 0) {
    return (
      <NoResult
        top={60}
        title={`주변에 ${
          categoryType === EPlaceCategoryType.POPULAR ? '티맵인기 ' : ''
        }장소가 없습니다.`}
        description={'다른 위치에서 탐색해 보세요.'}
      />
    );
  }

  return (
    <>
      <ul className={s.list_wrap}>
        {list.map((v, i) => {
          return (
            <InView
              key={`${v.poiId}-${i}`}
              as="li"
              className={classNames(s.item_wrap, {
                [s.hidden]: isFixFirstItem,
              })}
              data-type="poi"
              data-id={v.listId}
              threshold={0.5}
              onChange={(e) => changeViewingItems(i, e)}
              data-focus={activePoi === v.listId}
              onClick={() => handleClickItem(v, i, {tags: generateTagComponentList(v)})}
            >
              <PoiListItem
                idx={i}
                poiData={{
                  ...v,
                  listName: v.poiName,
                  imageInfo: imageCounter.getListByViewport(v.imageInfo),
                }}
                actionButtonType={getAppActionButton({reqMode, reqType, extra})}
                onClickRouteButton={() =>
                  handleClickRoute(v, i, {tags: generateTagComponentList(v)})
                }
                focus={activePoi === v.listId}
                onClickItem={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClickItem(v, i, {tags: generateTagComponentList(v)});
                }}
              />
              <BlossomBikeButton
                poiData={v}
                onClick={() => {
                  sendClickLog('tap.to_bike', {
                    pkey: v.pkey,
                  });
                }}
              />
            </InView>
          );
        })}
      </ul>

      {list.length > 0 && (
        <InView
          onChange={(isVisible) => {
            isVisible && !apiStatus.loading && onFetchMore?.();
          }}
          threshold={0.9}
        >
          <div style={{height: '1px'}} />
        </InView>
      )}
    </>
  );
});

export default PlaceList;
