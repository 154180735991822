import {useMemo, useEffect, useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import TMapSender from '@lcc/tmap-inapp';

import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchPosList, fetchPlaceList, fetchPopularList} from 'ducks/place/slice';
import {ECarFuel} from 'ducks/userInfo/types';

import {EPlaceCategoryType, EPlaceFeature, ESkeletonType} from 'types/App';
import {EButtonType} from 'types/Button';
import {EListMode, TListDrawerResult} from 'types/ListDrawer';
import {TLonLat} from 'types/Map';
import {EPerformanceLogKey} from 'types/Log';

import {CATEGORY_SHORT_ADDRESS_KEY, CATEGORY_SHORT_ADDRESS_VALUE, FuelMap} from 'constant/Poi';
import {
  CATEGORY_FILTER_TOP_AREA,
  CATEGORY_NO_FILTER_TOP_AREA,
  HEADER_CATEGORY_LIST_MARGIN,
  MAX_MARKER_DOUBLE_TITLE_HEIGHT,
  MAX_MARKER_HEIGHT,
  MAX_MARKER_TITLE_HEIGHT,
  MAX_MARKER_WIDTH,
  SEARCH_RESULT_CENTER_MAP_PERCENT_SIZE,
  TITLE_AREA_HEIGHT,
} from 'constant/Size';
import {EActionId, EPageType, TLA_PAGE_ID} from 'constant/Log';
import {DEFAULT_ZOOM} from 'constant/Map';

import useUserData from 'hooks/useUserData';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {usePerformanceLog} from 'hooks/usePerformanceLog';
import useSendPagePerformanceLog from 'hooks/useSendPagePerformanceLog';
import useSendPlacePageRefreshLog from 'hooks/useSendPlacePageRefreshLog';
import useMapOffset from 'hooks/useMapOffset';
import {useCategoryStorage} from 'hooks/useCategoryStorage';
import useLogger from 'hooks/useLogger';
import {useParamLog} from 'hooks/useParamLog';
import {useOnce} from 'hooks/useOnce';

import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';

import ua from 'utils/uaParser';
import {getValidLonLat} from 'utils/map';
import {init as initLog} from 'utils/logManager';

import PlaceListHeader from 'components/place/PlaceListHeader';
import DrawerContainer from 'components/DrawerContainer';
import PlaceMap from 'components/PlaceMap';
import PlaceList from 'components/place/PlaceList';
import Header from 'components/Header';
import Skeleton from 'components/Skeleton';
import VSMCompass from 'components/VSMCompass';
import BuildInfo from 'components/BuildInfo';
import RouteAddPopup from 'components/RouteAddPopup';
import ErrorReload from 'components/ErrorReload';

import s from 'styles/pages/PlaceCategoryPage.module.scss';
import {EXIST_FILTER_CATEGORY, GENERAL_PLACE_FILTER} from 'constant/PlaceFilter';
import {useValidCategory} from 'hooks/useValidCategory';
import {ESortOption} from 'types/Search';
import {useRemoteConfig} from 'hooks/useRemoteConfig';
import {PLACE_CONFIG} from 'constant/Firebase';
import BuildInfoTempZoom from 'components/BuildInfoTempZoom';

const PlaceCategoryPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {queries, tailParam} = useParseQueryLocation();
  const {
    userPosition,
    rdPlace,
    calloutInfo,
    userInteraction,
    apiLoaded,
    rdUserInfo,
    windowSize,
    nowCenter,
    isLogInitialized,
    remoteUpdateTime,
  } = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    nowCenter: state.map.nowCenter,
    rdPlace: state.place,
    calloutInfo: state.userInteraction.calloutInfo,
    userInteraction: state.userInteraction,
    apiLoaded: state.place.loaded,
    rdUserInfo: state.userInfo,
    windowSize: state.layout.windowSize,
    isLogInitialized: state.log.isInitialize,
    remoteUpdateTime: state.remote.lastUpdateTime || 0,
  }));
  const {data} = rdPlace;
  const {normalLog} = usePerformanceLog();
  const {sendPlacePageLog, sendPlaceResultLog, sendClickLogWithMapView} = useLogger();

  const {centerOffset, getBoundsPaddingAndOffset} = useMapOffset();

  const [initLoad, setInitLoad] = useState(false);
  const headerMarginTop = useMemo(() => TITLE_AREA_HEIGHT + HEADER_CATEGORY_LIST_MARGIN, []);
  const validCenter = useMemo(
    () => getValidLonLat({lat: queries.centerLat, lon: queries.centerLon}) || nowCenter,
    [queries, nowCenter]
  );
  const validUserPos = useMemo(
    () => getValidLonLat({lat: queries.userLat, lon: queries.userLon}) || userPosition,
    [queries, userPosition]
  );
  const [prevCenter, setPrevCenter] = useState<TLonLat | undefined>();
  const {referrer} = useParamLog();

  useRemoteConfig(PLACE_CONFIG);
  useSendPagePerformanceLog({isLogInitialized});
  useSendPlacePageRefreshLog({isLogInitialized});

  const {getLastVisitFilter} = useCategoryStorage();
  const {target, isValidCheckFinish} = useValidCategory(queries);

  useEffect(() => {
    if (!initLoad && apiLoaded) {
      setInitLoad(true);
    }
  }, [initLoad, apiLoaded]);

  useUserData();

  const {pageTitle, hasCategoryFilter} = useMemo(() => {
    const category = target;
    const categoryId = category?.type;
    const title = category?.title;
    const hasFilter = categoryId && EXIST_FILTER_CATEGORY.includes(categoryId);

    return {
      target: category,
      pageTitle: title,
      hasCategoryFilter: !!hasFilter,
    };
  }, [target]);

  const getList = useCallback(
    (pagingParam = {}) => {
      if (target) {
        dispatch(actions.userInteraction.setDragMap(false));

        if (target.type === EPlaceCategoryType.POPULAR) {
          return dispatch(fetchPopularList({...prevCenter, ...pagingParam}));
        } else if (target.categories) {
          return dispatch(fetchPosList({...prevCenter, ...pagingParam}));
        } else {
          return dispatch(fetchPlaceList({...prevCenter, ...pagingParam, query: target.type}));
        }
      }
    },
    [dispatch, prevCenter, target]
  );

  const fetchMore = useCallback(() => dispatch(actions.place.updateNextPage()), [dispatch]);

  useOnce(!!target?.type, () => {
    target?.type && dispatch(actions.category.setCurrentCategory(target.type));
  });

  useOnce(!!rdUserInfo.accessKey && target, () => {
    initLog({
      sessionId: rdUserInfo.sessionId,
      accessKey: rdUserInfo.accessKey,
      sessionKey: rdUserInfo.sessionKey,
      userKey: rdUserInfo.userKey,
      deviceId: rdUserInfo.device.deviceId,
      carrierName: rdUserInfo.device.carrierName,
      pageId: `${TLA_PAGE_ID}/${target?.type || ''}`,
      pageType: EPageType.PLACE_CATEGORY,
      title: 'TMAP 주변',
      referrer,
    });

    dispatch(actions.log.setInitialize(true));
    normalLog(EPerformanceLogKey.WEB_LAUNCH);
  });

  useOnce(isLogInitialized && rdPlace.loaded, () => {
    sendPlacePageLog(target?.title);
  });

  useOnce(rdPlace.loaded, () => {
    sendPlaceResultLog(target?.title);
  });

  useEffect(() => {
    const isShortAddress =
      parseInt(tailParam?.[CATEGORY_SHORT_ADDRESS_KEY], 10) ===
      parseInt(CATEGORY_SHORT_ADDRESS_VALUE, 10);

    dispatch(actions.category.setShowShortAddress(isShortAddress));

    return () => {
      dispatch(actions.place.reset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnce(validCenter && validUserPos && target, () => {
    const userFuel = FuelMap[(queries.userFuel as ECarFuel) || rdUserInfo.carInfo?.fuel];
    const urlFilter = !!queries.filter1
      ? {
          placeQuery: queries.filter1,
          oilCompany: queries.filter2 ? [queries.filter2] : undefined,
        }
      : null;

    const lastVisitFilter = getLastVisitFilter(target?.type) || {};

    const specialFilter = target?.type ? GENERAL_PLACE_FILTER[target?.type] : {};

    const customConfigFilter = target?.categories ? {categories: target?.categories} : {};

    const customConfigSort = target?.sort?.default;

    const filters = urlFilter
      ? urlFilter
      : {
          ...specialFilter,
          ...customConfigFilter,
          // userFuel 설정시 query가 없을 경우 store 값으로 설정. 네비에서 접근시에 query없이 들어오는 경우가 있음. 외부 링크로 들어오는 경우 query로 filter 적용할 경우가 있음.
          placeQuery: target?.type === EPlaceCategoryType.OIL_GAS_ALL ? userFuel : undefined,
          ...(lastVisitFilter || {}),
        };

    dispatch(actions.place.resetAllFilters(filters));

    if (urlFilter && queries.sort) {
      dispatch(actions.place.updateSort(queries.sort));
    } else {
      dispatch(actions.place.updateSort(customConfigSort));
    }

    if (target?.type === EPlaceCategoryType.HOTL_ALL && queries.filter2) {
      dispatch(actions.place.updatePlaceFeatureIds([EPlaceFeature.TMAP_RESERVATION]));
    }

    if (!!queries.filter) {
      // deeplink key: filter, value: reservation,waiting (, 구분자)
      const filterFeatures = queries.filter.split(',').map((f) => f.trim());
      const featureIds: EPlaceFeature[] = [];

      if (filterFeatures.includes('reservation')) {
        featureIds.push(EPlaceFeature.TMAP_RESERVATION);
      }

      if (filterFeatures.includes('waiting')) {
        featureIds.push(EPlaceFeature.TMAP_WAITING);
      }

      dispatch(actions.place.updatePlaceFeatureIds(featureIds));
    }

    setPrevCenter(validCenter);
    validUserPos && dispatch(actions.map.setUserPosition(validUserPos));
    validCenter && dispatch(actions.map.setNowCenter(validCenter));
    getList({...validCenter, ...filters});
  });

  const handleRefresh = useCallback(
    (refreshCenter) => {
      dispatch(actions.place.resetList());
      setPrevCenter(refreshCenter);
      getList(refreshCenter);
    },
    [dispatch, getList]
  );

  const handleChange = useCallback(
    (param = {}) => {
      dispatch(actions.place.resetList());

      if (target?.type === EPlaceCategoryType.PARKING_LOT && !param.sort) {
        dispatch(actions.place.updateSort(ESortOption.DEST_SCORE));
      }

      if (target?.type === EPlaceCategoryType.OIL_EV_ALL && !param.sort) {
        dispatch(actions.place.updateSort(ESortOption.DEST_SCORE));
      }
      getList({...param});
    },
    [dispatch, getList, target?.type]
  );

  const handleGoBack = useCallback(() => {
    if (calloutInfo) {
      dispatch(actions.userInteraction.clearCalloutInfo());
      dispatch(actions.userInteraction.setInteraction({drawerMode: EListMode.CENTER}));
    } else {
      if (ua.isInApp) {
        TMapSender.onBackKeyPressed();
      } else {
        history.goBack();
      }
    }
  }, [dispatch, calloutInfo, history]);

  if (!target && isValidCheckFinish) {
    return (
      <div className={s.error_wrap}>
        <div className={s.header}>
          <Header
            leftButton={EButtonType.BACK}
            onGoBack={handleGoBack}
            onClickLeft={() => {
              sendClickLogWithMapView(EActionId.TAP_BACK);
            }}
            gradient={true}
          />
        </div>
        <ErrorReload />
      </div>
    );
  }

  return (
    <VSMInterfaceProvider>
      <div className={s.header}>
        <Header
          leftButton={EButtonType.BACK}
          onGoBack={handleGoBack}
          onClickLeft={() => {
            sendClickLogWithMapView(EActionId.TAP_BACK);
          }}
          title={remoteUpdateTime > 0 ? pageTitle : <div className={s.title_holder} />}
          gradient={true}
        />
        <VSMCompass className={s.vsm_compass} />
      </div>
      <DrawerContainer
        isHideToTop={true}
        isPadColorWhite={true}
        list={data.list}
        listMode={userInteraction.drawerMode}
        onRefresh={handleRefresh}
        onChangeListMode={(drawerMode) => {
          dispatch(actions.userInteraction.setInteraction({drawerMode}));
        }}
        mapComponent={(drawerProps: TListDrawerResult) => {
          const maxMarkerTitleHeight =
            target && [`${EPlaceCategoryType.OIL_EV_ALL}`].includes(target.type)
              ? MAX_MARKER_DOUBLE_TITLE_HEIGHT
              : MAX_MARKER_TITLE_HEIGHT;

          const {boundsPadding, boundOffset} = getBoundsPaddingAndOffset(drawerProps, {
            headerHeight: TITLE_AREA_HEIGHT,
            maxMarkerHeight: 0,
            maxMarkerWidth: MAX_MARKER_WIDTH,
            maxMarkerTitleHeight,
          });

          return initLoad && boundsPadding && validUserPos ? (
            <PlaceMap
              priorityBounds={{top: MAX_MARKER_HEIGHT}}
              boundsPadding={boundsPadding}
              boundOffset={boundOffset}
              list={data.list}
              centerOffset={centerOffset}
              defaultCenter={prevCenter}
              initZoom={
                queries.defaultZoom
                  ? parseFloat(queries.defaultZoom as string)
                  : rdPlace.loaded && data.list.length === 0
                  ? DEFAULT_ZOOM
                  : undefined
              }
              initLoad={initLoad}
              useDataCenterPointBound={true}
              personMarkerLayerVisible={true}
            />
          ) : (
            <></>
          );
        }}
        listHeaderComponent={
          <Skeleton
            type={
              hasCategoryFilter
                ? ESkeletonType.CATEGORY_HEADER
                : ESkeletonType.CATEGORY_NO_FILTER_HEADER
            }
            apiStatus={rdPlace}
          >
            <PlaceListHeader onChange={handleChange} hasFilter={hasCategoryFilter} />
          </Skeleton>
        }
        listComponent={
          <>
            <Skeleton type={ESkeletonType.PLACE_RESULT_LIST} apiStatus={rdPlace}>
              <PlaceList
                apiStatus={rdPlace}
                categoryType={target?.type}
                onFetchMore={fetchMore}
                onError={() => {
                  getList();
                }}
              />
            </Skeleton>
          </>
        }
        isLastPage={data.isLastPage}
        drawerOptions={{
          centerHeight: parseInt(
            `${(windowSize.height * SEARCH_RESULT_CENTER_MAP_PERCENT_SIZE) / 100}`,
            10
          ),
          topAreaHeight: TITLE_AREA_HEIGHT - HEADER_CATEGORY_LIST_MARGIN,
          listHandleHeight: hasCategoryFilter
            ? CATEGORY_FILTER_TOP_AREA
            : CATEGORY_NO_FILTER_TOP_AREA,
        }}
        extraMargin={0}
        paddingTop={headerMarginTop}
        saveToggleButtonVisible={true}
        showFixedTopBtn={true}
      />

      <RouteAddPopup />

      <BuildInfo />
      {/* 저장 확인용 임시 */}
      <BuildInfoTempZoom />
    </VSMInterfaceProvider>
  );
};

export default PlaceCategoryPage;
