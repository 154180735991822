import classNames from 'classnames';
import Thumbnail from 'components/Thumbnail';
import {TPoiImageInfo} from 'types/App';
import {getImageUrl} from 'utils/url';
import s from 'styles/components/ranking/RankingPoiImageList.module.scss';

type TProps = {
  images: {url: string; url2: string}[];
  poiName: string;
  className?: string;
  isCardMode?: boolean;
};

const RankingPoiImageList = ({images, poiName, className, isCardMode}: TProps) => {
  return (
    <div
      className={classNames(s.image_wrap, className)}
      style={{'--image-count': images.length || 1} as React.CSSProperties}
    >
      {images.map((image, imgIdx) => (
        <li
          key={imgIdx}
          className={classNames(s.image, {
            [s.round_left]: imgIdx === 0,
            [s.round_right]: imgIdx === images.length - 1,
            [s.is_card_mode]: isCardMode,
          })}
        >
          <Thumbnail imgSrc={getImageUrl(image as TPoiImageInfo) || ''} alt={`${poiName} 이미지`} />
        </li>
      ))}
    </div>
  );
};

export default RankingPoiImageList;
