import {Fragment, useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import TMapSender from '@lcc/tmap-inapp';
import ErrorReload from 'components/ErrorReload';
import NoResult from 'components/NoResult';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import useSearchResult from 'hooks/useSearchResult';
import SearchRecommendPoiItem from './SearchRecommendPoiItem';
import {ReactComponent as IcoArrowRightBold} from 'resource/images/@tmds_element/ico_arrow_right_bold.svg';
import {ReactComponent as IcoNoticeSolid} from 'resource/images/@tmds_solid/ico_notice_solid.svg';
import {ReactComponent as IconReload} from 'resource/images/ico_reload.svg';
import {useFocusDrawerList} from 'hooks/useFocusDrawerList';
import {TSearchPoi} from 'types/App';
import InView from 'react-intersection-observer';
import {generateTagComponentList} from 'hooks/usePoiMainContentTag';
import useLogger from 'hooks/useLogger';
import {
  DSP_AD_POSITION_IDX,
  EAdCode,
  ETMapBannerCode,
  isBannerTestEnv,
  NAVER_SA_POSITION_IDX,
  SEARCH_BANNER_AD_STEP,
  SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
  TEST_BANNER_PROVIDER_CONFIG,
} from 'constant/Ads';
import SearchNaverAd from './SearchNaverAd';
import {fetchSearchMore} from 'ducks/search/slice';
import {ReloadResume} from 'components/ReloadResume';
import {AdBanner} from 'components/AdBanner';
import {getLocalAdLogs, useTMapAds} from 'hooks/useTMapAds';
import {useAdRequestParams} from 'hooks/useAdRequestParams';
import {useAdBanner} from 'context/AdBannerContext';
import useFavorite from 'hooks/useFavorite';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';

import s from 'styles/components/search/SearchRecommendList.module.scss';

type TProps = {
  onReload?: () => void;
  onReset?: () => void;
};

const SearchRecommendList = ({onReload, onReset}: TProps) => {
  const {queries} = useParseQueryLocation();
  const dispatch = useAppDispatch();

  const {searchList, isError, isLoading, isEmpty, ...visibleStatus} = useSearchResult();
  const {
    tmapFamousYn,
    openNowYn,
    isWaitingReservation,
    poiParkYn,
    categories,
    isLandscape,
    isLogInitialized,
  } = useAppSelector((state) => ({
    tmapFamousYn: state.search.tmapFamousYn,
    openNowYn: state.search.openNowYn,
    isWaitingReservation: state.search.isWaitingReservation,
    poiParkYn: state.search.poiParkYn,
    categories: state.search.categories,
    isLandscape: state.layout.appSize.isLandscape,
    isLogInitialized: state.log.isInitialize,
  }));
  const {checkIsFavorite} = useFavorite({
    list: searchList as TSearchPoi[],
  });
  const {sendClickLog, sendSearchResultItemClickLog, sendClickLogWithMapView} = useLogger();
  const {activePoi, changeViewingItems} = useFocusDrawerList(searchList as TSearchPoi[]);
  const hasPoiAd = useMemo(
    () => (searchList as TSearchPoi[]).find((v) => v.special.advertiseInfo.isPoiAdvertiseYn),
    [searchList]
  );

  const {isAdTop} = useAdBanner();
  const {requestSetting} = useAdRequestParams({
    inventoryCode: ETMapBannerCode.SEARCH_KEYWORD,
  });
  const {callCompleteLog} = useTMapAds(requestSetting);

  const handleShowMore = useCallback(
    (isVisible) => {
      isVisible && dispatch(fetchSearchMore());
    },
    [dispatch, isLoading]
  );

  const saPosition = useMemo(() => {
    if (searchList.length < 2) {
      return -1;
    }
    if (searchList.length === 2) {
      return NAVER_SA_POSITION_IDX - 1;
    }

    return NAVER_SA_POSITION_IDX;
  }, [searchList]);

  const dspPosition = useMemo(() => {
    if (searchList.length <= DSP_AD_POSITION_IDX) {
      return -1;
    }

    // https://tmobi.atlassian.net/browse/SEARCH-234
    // 2번째 DSP 배너 노출시, POI AD는 list 결과 순서에 포함되지 않음. total api 응답값에서는 포함돼서 내려옴
    return DSP_AD_POSITION_IDX;
  }, [searchList]);

  const hasFilter = useMemo(
    () =>
      tmapFamousYn ||
      openNowYn ||
      isWaitingReservation ||
      poiParkYn ||
      (categories && categories.length > 0),
    [tmapFamousYn, openNowYn, isWaitingReservation, poiParkYn, categories]
  );

  const handleClickPoiAd = useCallback(
    (v, clickTo) => {
      const {click} = getLocalAdLogs(v.special?.advertiseInfo);

      click && callCompleteLog(click, {clickTo});
    },
    [callCompleteLog]
  );

  const handlePoiAdShow = useCallback(
    (v) => {
      const {vimp} = getLocalAdLogs(v.special?.advertiseInfo);

      vimp && callCompleteLog(vimp, {updateId: true});
    },
    [callCompleteLog]
  );

  const renderAdBanner = useCallback(
    (adCode) => {
      const customOptions =
        adCode === EAdCode.SEARCH_RESULT_LAST
          ? {inner: {inventoryCode: ETMapBannerCode.SEARCH_KEYWORD_SECOND}}
          : {};
      return (
        <div className={s.banner_wrap}>
          <ReloadResume minHeight={56}>
            <AdBanner
              isLogInitialized={isLogInitialized}
              visibleLandscape={true}
              adCode={adCode}
              adTypeStep={SEARCH_BANNER_AD_STEP}
              adTypeOption={{
                ...SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
                ...customOptions,
                ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
              }}
              logData={{
                custom: {search_query: queries.searchQuery},
                includeTicketId: true,
              }}
            />
          </ReloadResume>
        </div>
      );
    },
    [isLogInitialized, queries.searchQuery]
  );

  if (isError) {
    return <ErrorReload onReload={onReload} />;
  }

  if (isEmpty) {
    return (
      <>
        {renderAdBanner(EAdCode.SEARCH_RESULT_CS)}
        <NoResult
          top={60}
          title={'검색 결과가 없습니다.'}
          description={hasFilter ? '' : '혹시 찾으시는 장소가 없나요?'}
          buttonProps={{
            text: hasFilter ? (
              <div className={s.no_result_button}>
                <div className={s.icon}>
                  <IconReload width={20} height={20} />
                </div>
                필터 초기화
              </div>
            ) : (
              '새장소 등록'
            ),
            onClick: () => {
              if (hasFilter) {
                sendClickLog('tap.filter_reset');
                onReset?.();
              } else {
                TMapSender.registerNewPoi(queries.searchQuery);
              }
            },
          }}
        />
      </>
    );
  }

  return (
    <div className={s.wrap}>
      {searchList.map((item, idx) => {
        const tags = generateTagComponentList(item as TSearchPoi);
        const isLocalAd = (item as TSearchPoi).special?.advertiseInfo?.isPoiAdvertiseYn;

        return (
          <Fragment key={(item as TSearchPoi).listId}>
            {!hasPoiAd && isAdTop && idx === 0 && renderAdBanner(EAdCode.SEARCH_RESULT_CS)}
            <InView
              as="li"
              className={s.list}
              data-type="poi"
              data-id={(item as TSearchPoi).listId}
              threshold={isLandscape && idx === 0 ? 0.2 : 0.5}
              onChange={(e) => changeViewingItems(e, idx)}
              data-focus={activePoi === (item as TSearchPoi).listId}
            >
              <SearchRecommendPoiItem
                poiData={item as any} // imageInfo 그대로 노출, 갯수 제한 처리 없이 (서버에서 최대 10개로 내려줌)
                onClickItem={() => {
                  sendSearchResultItemClickLog('list_tap.poi_detail', {
                    index: idx,
                    tags,
                  });
                  isLocalAd && handleClickPoiAd(item as TSearchPoi, 'place');
                }}
                onClickFavorite={(isOn) => {
                  if (isOverNewFavoriteVersion()) {
                    sendClickLog('tap.callout_save', {
                      index: 2,
                      pkey: item.pkey,
                      search_recommend: true,
                      type: 'list',
                      is_poi_ad: isLocalAd,
                    });
                  } else {
                    sendClickLog('tap.poi_bookmark', {
                      status: isOn,
                      pkey: item.pkey,
                      is_poi_ad: isLocalAd,
                    });
                  }
                  isLocalAd && handleClickPoiAd(item as TSearchPoi, 'favorite');
                }}
                onPoiAdShow={handlePoiAdShow}
                isFavorite={checkIsFavorite(item)}
              />
            </InView>
            {!hasPoiAd && !isAdTop && idx === 0 && renderAdBanner(EAdCode.SEARCH_RESULT)}
            {idx === (hasPoiAd ? saPosition + 1 : saPosition) && (
              <SearchNaverAd className={s.keyword_banner_wrap} searchQuery={queries.searchQuery} />
            )}
            {hasPoiAd && idx === dspPosition && renderAdBanner(EAdCode.SEARCH_RESULT_LAST)}
          </Fragment>
        );
      })}
      {visibleStatus.isShowMoreChecker && (
        <InView
          onChange={handleShowMore}
          threshold={0.01}
          rootMargin="100px 0px 0px 0px"
          className={s.more_checker}
        />
      )}
      {searchList.length > 0 && visibleStatus.isLastPage && (
        <div className={s.regist_new_place}>
          <button
            className={s.btn_regist}
            onClick={() => {
              sendClickLogWithMapView('tap.addpoi', {
                search_query: queries.searchQuery,
              });
              TMapSender.registerNewPoi(queries.searchQuery);
            }}
          >
            <i className={s.notice}>
              <IcoNoticeSolid />
            </i>
            <span className={s.text}>혹시 찾으시는 장소가 없다면 등록해주세요</span>
            <i className={s.landing}>
              <IcoArrowRightBold />
            </i>
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchRecommendList;
