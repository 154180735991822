import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {ECategoryGroup, TPoiImageInfo, TSpecialData} from 'types/App';
import {TPoiDataJson} from '@lcc/tmap-inapp';
import {BUSINESS_HOUR_LABEL} from 'utils/special';
import {ItemTag} from 'components/ItemTag';
import {useParsePoiData} from 'hooks/useParsePoiData';
import useAppScheme from 'hooks/useAppScheme';
import useAddress from 'hooks/useAddress';
import useThrottle from 'hooks/useThrottle';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useMoveToTarget from 'hooks/useMoveToTarget';
import Thumbnail from 'components/Thumbnail';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {IcoStarFilled} from 'components/@tmds/icons/IcoStarFilled';
import {IcoStar} from 'components/@tmds/icons/IcoStar';
import SearchPoiAdItem from './SearchPoiAdItem';

import s from 'styles/components/search/SearchRecommendPoiItem.module.scss';

export const MAX_CATEGORY_NAME = 10;

export type TPoiData = {
  addressNameDepth2?: string;
  listName?: string;
  categoryName?: string;
  categoryGroup?: ECategoryGroup;
  special?: TSpecialData;
  imageInfo?: TPoiImageInfo[];
  pkey: string;
} & TPoiDataJson;

type TProps = {
  poiData: TPoiData;
  onClickItem?: (e) => void;
  onClickFavorite?: (isOn) => void;
  onPoiAdShow?: (v) => void;
  isFavorite?: boolean;
};

const SearchRecommendPoiItem = ({
  poiData,
  onClickItem,
  onClickFavorite,
  onPoiAdShow,
  ...props
}: TProps) => {
  const inApp = useAppScheme();
  const ableToRun = useThrottle();
  const {fullAddress} = useAddress(poiData);
  const {distance, imageInfo: images, headingScore, tags, isLocalAd} = useParsePoiData(poiData);
  const {moveToDetail} = useMoveToTarget();
  const [isFavorite, setIsFavorite] = useState(!!props.isFavorite);

  const businessHoursInfo =
    poiData.special?.businessHourStatus && BUSINESS_HOUR_LABEL[poiData.special.businessHourStatus];
  const [label, textColor] = businessHoursInfo || [];

  const handleClickFavorite = useCallback(
    (e) => {
      if (!poiData || !ableToRun()) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      inApp
        .toggleSearchFavorite({
          ...poiData,
          address: fullAddress,
        })
        .then(({isOn}) => {
          setIsFavorite(isOn);
          onClickFavorite?.(isOn);
        });
    },
    [fullAddress, poiData, inApp, ableToRun, onClickFavorite]
  );

  const handleClickItem = useCallback(
    (e) => {
      if (!ableToRun()) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      onClickItem?.(e);
      moveToDetail(parsePoiInfoToNavInfo(poiData));
    },
    [ableToRun, moveToDetail, poiData, onClickItem]
  );

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  return isLocalAd ? (
    <SearchPoiAdItem
      poiData={poiData}
      onClickItem={handleClickItem}
      onClickFavorite={handleClickFavorite}
      onShow={() => onPoiAdShow?.(poiData)}
      hasFavorite={true}
      isFavorite={isFavorite}
    />
  ) : (
    <div className={s.wrap} onClick={handleClickItem}>
      <div className={classNames(s.title_wrap)}>
        <div className={s.text_wrap}>
          <span className={s.title} data-style-node="title">
            {poiData.listName}
          </span>
          <span className={s.category} data-style-node="category">
            {poiData.categoryName?.slice(0, MAX_CATEGORY_NAME)}
          </span>
        </div>
        <div className={s.favorite} onClick={handleClickFavorite}>
          {isFavorite ? (
            <IcoStarFilled width={24} height={24} color="yellow500" />
          ) : (
            <IcoStar width={24} height={24} color="gray300" />
          )}
        </div>
      </div>

      <div className={s.info_wrap}>
        {!!businessHoursInfo && (
          <>
            <p data-color={textColor}>{label}</p>
            <IcoLineBetween color="gray200" width={16} height={16} />
          </>
        )}
        <p className={s.distance}>{distance}</p>
        <IcoLineBetween color="gray200" width={16} height={16} />
        <p className={s.address}>{poiData?.addressNameDepth2}</p>
        {!!headingScore && <IcoLineBetween color="gray200" width={16} height={16} />}
        {!!headingScore && <span className={s.count}>{headingScore}대 가는중</span>}
      </div>

      <div
        className={classNames(s.special_action, {
          [s.is_hide]: tags.length < 1 && images.length < 3,
        })}
      >
        {tags.length > 0 && (
          <p className={s.tag_list}>
            {tags.map((t, tIdx) => (
              <span className={s.tag} key={tIdx}>
                <ItemTag {...t} />
              </span>
            ))}
          </p>
        )}
        <div className={classNames(s.image_wrap, {[s.hide]: images.length < 3})}>
          <div className={s.image_list}>
            {images.map((image, imgIdx) => {
              return (
                <div className={s.image_container} key={imgIdx}>
                  <div className={s.thumbnail_wrap}>
                    <Thumbnail
                      imgSrc={getImageUrl(image, EImageSize.THUMBNAIL)}
                      className={s.img}
                      alt={`${poiData.listName} 이미지`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchRecommendPoiItem;
