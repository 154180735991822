import CustomSelectLayer from 'components/CustomSelectLayer';
import actions from 'ducks/actions';
import {
  getOilGasPlaceQueryList,
  getOilCompanyList,
  getOilGasPlaceFeatureList,
} from 'constant/Place';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {Link} from 'react-router-dom';
import {ReactComponent as IconArrowDown} from 'resource/images/@tmds_element/ico_arrow_down.svg';
import {useCallback, useMemo} from 'react';
import {FuelMap} from 'constant/Poi';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {EOilType, EPlaceFeatureOperator} from 'types/App';
import s from 'styles/components/place/PlaceListHeader.module.scss';
import classNames from 'classnames';
import {DEFAULT_FILTER} from 'hooks/usePlaceFilter';
import useLogger from 'hooks/useLogger';
import {EFilterActionId} from 'constant/Log';
import {checkValidPlaceOptions, makePlaceOptionLabel} from 'utils/general';

type TProps = {
  onChange: (param: Record<string, any>) => void;
};

export const PlaceListHeaderOilGasFilter = ({onChange}: TProps) => {
  const dispatch = useAppDispatch();
  const {sendFilterClickLog, sendClickLog, sendClickLogWithMapView} = useLogger();

  const oilGasQueryDimmed = useDimmedQuery({place: 'oil_gas_query'});
  const oilGasCompanyDimmed = useDimmedQuery({place: 'oil_gas_company'});
  const placeFeatureDimmed = useDimmedQuery({place: 'oil_gas_feature'});

  const {oilGasQuery, oilCompanyIds, placeFeatureIds, userFuel} = useAppSelector((state) => ({
    oilGasQuery: state.place.placeQuery,
    oilCompanyIds: state.place.oilCompany,
    placeFeatureIds: state.place.placeFeatureIds,
    userFuel: state.userInfo.carInfo?.fuel,
  }));

  const oilGasQueryOptions = useMemo(() => getOilGasPlaceQueryList(), []);
  const selectedOilGasQueryOption = useMemo(() => {
    const userOilGasQuery = (userFuel && FuelMap[userFuel]) || EOilType.GASOLINE;

    return oilGasQueryOptions.find((v) => v.id === (oilGasQuery || userOilGasQuery));
  }, [oilGasQuery, oilGasQueryOptions, userFuel]);

  const oilGasCompanyOptions = useMemo(
    () => getOilCompanyList(selectedOilGasQueryOption?.id),
    [selectedOilGasQueryOption]
  );
  const selectedOilCompanyOptions = useMemo(() => {
    return oilGasCompanyOptions.filter((v) => oilCompanyIds?.includes(v.id));
  }, [oilCompanyIds, oilGasCompanyOptions]);

  const placeFeatureOptions = useMemo(() => getOilGasPlaceFeatureList(), []);
  const selectedPlaceFeatureOptions = useMemo(() => {
    return placeFeatureOptions.filter((v) => placeFeatureIds?.includes(v.id));
  }, [placeFeatureIds, placeFeatureOptions]);

  const handleClickQueryItem = useCallback(
    (newItem) => {
      const isSelectAll = newItem.id === DEFAULT_FILTER.id;
      const id = isSelectAll ? undefined : newItem.id;
      const nowOilBrands = selectedOilCompanyOptions?.map((item) => item.brandId);
      const newOilBrands = getOilCompanyList(newItem.id).filter((item) =>
        nowOilBrands?.includes(item.brandId)
      );

      const newOilCompanyFilter = checkValidPlaceOptions(newOilBrands);

      dispatch(actions.place.updateFilter(id));
      dispatch(actions.place.updateOilCompany(newOilCompanyFilter));
      onChange({filter: id});
      oilGasQueryDimmed.close();

      sendFilterClickLog(EFilterActionId.FILTER_ITEM_1, {filter1: id || DEFAULT_FILTER.id});
    },
    [dispatch, onChange, oilGasQueryDimmed, sendFilterClickLog, selectedOilCompanyOptions]
  );

  const handleClickOilCompanyItem = useCallback(
    (newItemList) => {
      const newItemIds = checkValidPlaceOptions(newItemList);

      dispatch(actions.place.updateOilCompany(newItemIds));
      onChange({oilCompany: newItemIds});
      oilGasCompanyDimmed.close();

      sendFilterClickLog(EFilterActionId.FILTER_ITEM_2, {filter2: newItemIds});
    },
    [oilGasCompanyDimmed, onChange, dispatch, sendFilterClickLog]
  );

  const handleClickPlaceFeatureConfirm = useCallback(
    (newItemList) => {
      const newItemIds = checkValidPlaceOptions(newItemList);

      dispatch(actions.place.updatePlaceFeatureIds(newItemIds));
      dispatch(actions.place.updatePlaceFeatureOperator(EPlaceFeatureOperator.AND));
      onChange({placeFeatureIds: newItemIds});

      placeFeatureDimmed.close();

      sendFilterClickLog(EFilterActionId.FILTER_ITEM_3, {filter3: newItemIds});
    },
    [dispatch, onChange, placeFeatureDimmed, sendFilterClickLog]
  );

  const handleReset = useCallback(() => {
    sendClickLog('tap.reset');
  }, [sendClickLog]);

  return (
    <>
      <Link
        className={classNames(s.filter, {
          [s.on]:
            selectedOilGasQueryOption &&
            selectedOilGasQueryOption?.id !== oilGasQueryOptions[0]?.id,
        })}
        to={oilGasQueryDimmed.openLocation}
        onClick={() => {
          sendClickLogWithMapView(EFilterActionId.FILTER_1);
        }}
        draggable={false}
      >
        <span className={s.text}>
          {selectedOilGasQueryOption?.id !== EOilType.ALL
            ? selectedOilGasQueryOption?.label
            : '유종'}
        </span>
        <IconArrowDown className={s.icon_fold} />
      </Link>
      <Link
        className={classNames(s.filter, {
          [s.on]: selectedOilCompanyOptions.length > 0,
        })}
        to={oilGasCompanyDimmed.openLocation}
        onClick={() => {
          sendClickLogWithMapView(EFilterActionId.FILTER_2);
        }}
        draggable={false}
      >
        <span className={s.text}>{makePlaceOptionLabel(selectedOilCompanyOptions, '브랜드')}</span>
        <IconArrowDown className={s.icon_fold} />
      </Link>
      <Link
        className={classNames(s.filter, {
          [s.on]: selectedPlaceFeatureOptions.length > 0,
        })}
        to={placeFeatureDimmed.openLocation}
        onClick={() => {
          sendClickLogWithMapView(EFilterActionId.FILTER_3);
        }}
        draggable={false}
      >
        <span className={s.text}>
          {makePlaceOptionLabel(selectedPlaceFeatureOptions, '부가정보')}
        </span>
        <IconArrowDown className={s.icon_fold} />
      </Link>
      <CustomSelectLayer
        isOpen={oilGasQueryDimmed.isOpen}
        options={oilGasQueryOptions}
        selectedItem={selectedOilGasQueryOption || oilGasQueryOptions[0]}
        onClickItem={handleClickQueryItem}
      />
      <CustomSelectLayer
        multiple={true}
        isOpen={oilGasCompanyDimmed.isOpen}
        options={oilGasCompanyOptions}
        selectedItem={selectedOilCompanyOptions}
        onReset={handleReset}
        onConfirm={handleClickOilCompanyItem}
      />
      <CustomSelectLayer
        multiple={true}
        isOpen={placeFeatureDimmed.isOpen}
        options={placeFeatureOptions}
        selectedItem={selectedPlaceFeatureOptions}
        onReset={handleReset}
        onConfirm={handleClickPlaceFeatureConfirm}
      />
    </>
  );
};
