import {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {ReactComponent as IconArrowDown} from 'resource/images/@tmds_element/ico_arrow_down.svg';
import {EPlaceFeature, EPlaceFeatureOperator} from 'types/App';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useLogger from 'hooks/useLogger';
import {getAccommodationPlaceQueryList} from 'constant/Place';
import {EFilterActionId, EToggle} from 'constant/Log';
import {checkValidPlaceOptions, makePlaceOptionLabel} from 'utils/general';

import CustomSelectLayer from 'components/CustomSelectLayer';

import s from 'styles/components/place/PlaceListHeader.module.scss';

export const PlaceListHeaderAccommodationFilter = ({onChange}) => {
  const dispatch = useAppDispatch();
  const {sendClickLog, sendFilterClickLog} = useLogger();

  const accommodationQueryDimmed = useDimmedQuery({place: 'accommodation_query'});

  const {accommodationList, placeFeatureIds} = useAppSelector((state) => ({
    accommodationList: state.place.accommodationList,
    placeFeatureIds: state.place.placeFeatureIds,
  }));

  const accommodationListOptions = useMemo(() => getAccommodationPlaceQueryList(), []);
  const selectedAccommodationList = useMemo(
    () => accommodationListOptions.filter((v) => accommodationList?.includes(v.id)),
    [accommodationList, accommodationListOptions]
  );
  const isReservableClicked = useMemo(
    () => placeFeatureIds?.includes(EPlaceFeature.TMAP_RESERVATION),
    [placeFeatureIds]
  );

  const handleClickFilterItem = useCallback(
    (newItemList) => {
      const listIds = checkValidPlaceOptions(newItemList);

      sendFilterClickLog(EFilterActionId.FILTER_ITEM_1, {option: listIds?.join(', ')});
      dispatch(actions.place.updateAccommodationList(listIds));
      onChange({accommodationList: listIds});
      accommodationQueryDimmed.close();
    },
    [dispatch, accommodationQueryDimmed, onChange, sendFilterClickLog]
  );
  const handleClickReservable = useCallback(() => {
    const newFeatureIds = isReservableClicked ? [] : [EPlaceFeature.TMAP_RESERVATION];

    sendClickLog('tap.reservation', {
      status: isReservableClicked ? EToggle.OFF : EToggle.ON,
    });

    dispatch(actions.place.updatePlaceFeatureIds(newFeatureIds));
    dispatch(actions.place.updatePlaceFeatureOperator(EPlaceFeatureOperator.AND));
    onChange({accommodationList: checkValidPlaceOptions(selectedAccommodationList)});
  }, [dispatch, onChange, selectedAccommodationList, isReservableClicked, sendClickLog]);

  return (
    <>
      <Link
        className={classNames(s.filter, {
          [s.on]: selectedAccommodationList.length > 0,
        })}
        to={accommodationQueryDimmed.openLocation}
        onClick={() => {
          sendClickLog(EFilterActionId.FILTER_1);
        }}
        draggable={false}
      >
        <span className={s.text}>{makePlaceOptionLabel(selectedAccommodationList, '업종')}</span>
        <IconArrowDown className={s.icon_fold} />
      </Link>

      <div
        className={classNames(s.filter, {
          [s.on]: isReservableClicked,
        })}
        onClick={handleClickReservable}
      >
        <span className={s.text}>예약가능</span>
      </div>

      <CustomSelectLayer
        multiple={true}
        isOpen={accommodationQueryDimmed.isOpen}
        options={accommodationListOptions}
        selectedItem={selectedAccommodationList}
        onConfirm={handleClickFilterItem}
        onReset={() => {
          sendClickLog('tap.reset');
        }}
      />
    </>
  );
};
