import {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {EFilterActionId} from 'constant/Log';
import {ESortLabelTNow} from 'constant/Place';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useLogger from 'hooks/useLogger';
import {EPlaceCategoryType} from 'types/App';
import CustomSelectLayer, {TOption} from 'components/CustomSelectLayer';
import {IcoArrowDown} from 'components/@tmds/icons/IcoArrowDown';

import {ReactComponent as IcoDotBetween} from 'resource/images/@tmds_element/ico_dot_between.svg';

import s from 'styles/components/place/PlaceListHeaderSort.module.scss';

export const PlaceListHeaderSort = ({
  type: nowType,
  onChange,
}: {
  type: EPlaceCategoryType;
  onChange?: (item: TOption) => void;
}) => {
  const dispatch = useAppDispatch();
  const {placeResult, validCategoryPageList} = useAppSelector((state) => ({
    placeResult: state.place,
    validCategoryPageList: state.category.validCategoryPageList,
  }));

  const {sendClickLogWithMapView, sendSortClickLog} = useLogger();

  const sortList = useMemo(() => {
    const customSort = validCategoryPageList.find((c) => c.type === nowType)?.sort;
    return (
      customSort?.items?.map((item) => ({
        id: item,
        isDefault: item === customSort.default,
        label: ESortLabelTNow[item],
      })) || []
    );
  }, [nowType, validCategoryPageList]);

  const {nowSort, visibleSort} = useMemo(() => {
    return {
      nowSort: placeResult.sort,
      visibleSort: placeResult?.loaded && !placeResult?.error && placeResult.data?.list?.length > 0,
    };
  }, [placeResult]);
  const nowSortItem = useMemo(() => sortList.find((v) => v.id === nowSort), [nowSort, sortList]);
  const hasSortItem = nowSortItem && visibleSort;
  const sortDimmed = useDimmedQuery({place: 'sort'});
  const handleClickSortItem = useCallback(
    (newItem) => {
      dispatch(actions.place.updateSort(newItem.id));
      onChange?.(newItem);
      sortDimmed.close();

      sendSortClickLog(newItem.id);
    },
    [onChange, sortDimmed, dispatch, sendSortClickLog]
  );

  return (
    <>
      <div className={s.wrap}>
        <div className={s.item}>
          <span>지도중심</span>
          {hasSortItem && <IcoDotBetween />}
        </div>
        {hasSortItem &&
          (sortList.length > 1 ? (
            <Link
              className={s.item}
              to={sortDimmed.openLocation}
              onClick={() => {
                sendClickLogWithMapView(EFilterActionId.ORDER);
              }}
              draggable={false}
            >
              <span>{nowSortItem.label}</span>
              <IcoArrowDown color="gray600" width={12} height={12} />
            </Link>
          ) : (
            <div className={s.item}>
              <span className={s.text}>{nowSortItem.label}</span>
            </div>
          ))}
      </div>
      <CustomSelectLayer
        isOpen={sortDimmed.isOpen}
        options={sortList}
        selectedItem={nowSortItem}
        onClickItem={handleClickSortItem}
      />
    </>
  );
};
